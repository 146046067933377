import { useSelector } from 'react-redux';
import { Suspense } from 'react';

import { RouterProvider } from 'react-router-dom';
import router from './router';

import LoadingOverlay from './components/ui/LoadingOverlay';

import messages from './imports/i18n';
import { locale, loadMessages } from 'devextreme/localization';

export default function Root() {
    const userLocale = useSelector((state) => state.auth.locale);

    loadMessages(messages());
    locale(userLocale ? userLocale : navigator.language);

    return (
        <Suspense fallback={<LoadingOverlay visible />}>
            <RouterProvider router={router} />
        </Suspense>
    );
}
