import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: localStorage.getItem('user_token'),
        refresh: localStorage.getItem('user_refresh'),
        username: localStorage.getItem('user_name'),
        locale: localStorage.getItem('user_locale'),
    },
    reducers: {
        logout(state) {
            state.token = null;
            state.refresh = null;
            state.username = null;
            localStorage.removeItem('user_token');
            localStorage.removeItem('user_refresh');
            localStorage.removeItem('user_name');
        },
        refreshTokens(state, { payload }) {
            state.token = payload.token;
            state.refresh = payload.refresh;
            localStorage.setItem('user_token', payload.token);
            localStorage.setItem('user_refresh', payload.refresh);
        },
        setLocale(state, { payload }) {
            state.locale = payload;
            localStorage.setItem('user_locale', payload);
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
            state.token = payload.token;
            state.refresh = payload.refresh;
            state.username = payload.username;
            localStorage.setItem('user_token', payload.token);
            localStorage.setItem('user_refresh', payload.refresh);
            localStorage.setItem('user_name', payload.username);
        });
    },
});

export const { logout, refreshTokens, setLocale } = authSlice.actions;

export default authSlice.reducer;
