import dxMessages_de from 'devextreme/localization/messages/de.json';
import dxMessages_en from 'devextreme/localization/messages/en.json';

import de from './de.json';
import en from './en.json';

import { formatMessage } from 'devextreme/localization';

let translationMissingWarningsGivenFor = [];

export default function messages() {
    // Replace empty strings with keyValue of dictionary: Default translation is keyValue
    // else we got problem of translation not found console message
    Object.keys(en).forEach((key) => {
        if (en[key] === '') en[key] = key;
    });
    Object.keys(de).forEach((key) => {
        if (de[key] === '') de[key] = key;
    });

    return {
        de: {
            ...dxMessages_de.de,
            ...de,
        },
        en: {
            ...dxMessages_en.en,
            ...en,
        },
    };
}

export function message(msg, vars) {
    let payload = formatMessage(msg, vars);
    if (payload === '') {
        if (!translationMissingWarningsGivenFor.find((el) => el === msg)) {
            console.log('Translation missing for: ' + msg);
            translationMissingWarningsGivenFor.push(msg);
        }
        payload = msg;
    }
    return payload;
}
