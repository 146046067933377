import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { store } from '../store';
import { logout, refreshTokens } from '../store/auth';

export const serializedParams = (params, version = 1) => {
    const defaultParams = {
        version: version,
        type: 'web',
    };
    return encodeURI(JSON.stringify({ ...defaultParams, ...params }));
};

const baseQuery = fetchBaseQuery({
    baseUrl: 'https://cmsuebersichtapidev.www-data.de/',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');
        return headers;
    },
    credentials: 'include',
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401 || result.error?.status === 402) {
        const refreshResult = await baseQuery(
            {
                url: 'user/' + serializedParams(),
                method: 'PUT',
                body: { refresh: store.getState()?.auth?.refresh },
            },
            api,
            extraOptions
        );
        if (refreshResult.data) {
            api.dispatch(refreshTokens(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }

    return result;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Auth', 'Domains', 'TLDs', 'Servers'],
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => {
                const bearerToken = window.btoa(credentials.username + ':' + credentials.password);
                return {
                    url: 'user/' + serializedParams(),
                    method: 'GET',
                    headers: {
                        authorization: 'Bearer ' + bearerToken,
                    },
                };
            },
            providesTags: ['Auth'],
        }),
    }),
});

export const { useLoginMutation, endpoints } = api;
