import { useNavigate } from 'react-router-dom';

import { useLoginMutation } from '../api';

import { message } from '../imports/i18n';

import { Form as RouterForm } from 'react-router-dom';
import { Form as UIForm, SimpleItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useDispatch } from 'react-redux';
import { logout } from '../store/auth';

const formData = {
    username: '',
    password: '',
};

export default function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login, { isLoading }] = useLoginMutation();

    const submit = async (event) => {
        event.preventDefault();
        dispatch(logout()); // clear store to make sure there are no tokens etc left
        try {
            await login(formData).unwrap();
            navigate('/dashboard');
        } catch (err) {
            let message = 'Fehler beim Einloggen.';
            if (err.status) message += ' (Code ' + err.status + ')';
            notify({
                message,
                type: 'error',
                width: 'auto',
            });
        }
    };

    return (
        <RouterForm id='login-form' className='relative' onSubmit={submit}>
            {isLoading && (
                <div className='absolute flex w-full h-full bg-white opacity-80 items-center justify-center'>
                    <LoadIndicator height={40} width={40} />
                </div>
            )}
            <UIForm id='loginForm' formData={formData} labelMode='hidden' colCount={2} width='330'>
                <SimpleItem
                    dataField='username'
                    name='username'
                    label={{ text: message('Username') }}
                    editorOptions={{ placeholder: message('Username') }}
                    colSpan={2}
                    disabled={isLoading}
                    isRequired
                />
                <SimpleItem
                    dataField='password'
                    name='password'
                    label={{ text: message('Password') }}
                    editorOptions={{ placeholder: message('Password'), mode: 'password' }}
                    cssClass='mb-4'
                    colSpan={2}
                    disabled={isLoading}
                    isRequired
                />
                <ButtonItem horizontalAlignment='left'>
                    <ButtonOptions
                        text={message('Recover password')}
                        stylingMode='text'
                        width='auto'
                        elementAttr={{ class: 'buttonSmall' }}
                    />
                </ButtonItem>
                <ButtonItem>
                    <ButtonOptions
                        text={message('Login')}
                        type='default'
                        useSubmitBehavior={true}
                        disabled={isLoading}
                    />
                </ButtonItem>
            </UIForm>
        </RouterForm>
    );
}
