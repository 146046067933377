import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import LoginView from './routes/login';
import ErrorView from './routes/error';

// import BackendView from './routes/backend';
// import DashboardView from './routes/backend/dashboard';
// import DomainsView from './routes/backend/domains';
// import TLDsView from './routes/backend/tlds';
// import CustomersView from './routes/backend/customers';
// import CMSView from './routes/backend/cms';
// import ServersView from './routes/backend/servers';
// import ConfigView from './routes/backend/config';
// import LogoutView from './routes/backend/logout';

const BackendView = lazy(() => import('./routes/backend'));
const DashboardView = lazy(() => import('./routes/backend/dashboard'));
const DomainsView = lazy(() => import('./routes/backend/domains'));
const TLDsView = lazy(() => import('./routes/backend/tlds'));
const CustomersView = lazy(() => import('./routes/backend/customers'));
const CMSView = lazy(() => import('./routes/backend/cms'));
const ServersView = lazy(() => import('./routes/backend/servers'));
const ConfigView = lazy(() => import('./routes/backend/config'));
const LogoutView = lazy(() => import('./routes/backend/logout'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <LoginView />,
        errorElement: <ErrorView />,
    },
    {
        element: <BackendView />,
        children: [
            {
                errorElement: <ErrorView />,
                children: [
                    {
                        index: true,
                        path: '/dashboard',
                        element: <DashboardView />,
                    },
                    {
                        path: '/domains',
                        element: <DomainsView />,
                    },
                    {
                        path: '/tlds',
                        element: <TLDsView />,
                    },
                    {
                        path: '/customers',
                        element: <CustomersView />,
                    },
                    {
                        path: '/cms',
                        element: <CMSView />,
                    },
                    {
                        path: '/servers',
                        element: <ServersView />,
                    },
                    {
                        path: '/config',
                        element: <ConfigView />,
                    },
                    {
                        path: '/logout',
                        element: <LogoutView />,
                    },
                ],
            },
        ],
    },
]);

export default router;
