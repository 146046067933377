import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { message } from '../imports/i18n';

import LoginForm from '../components/LoginForm';

import cmsMonitoringLogo from '../assets/cmsmonitoring.svg';

export default function LoginView() {
    const user = useSelector((state) => state.auth.username);

    if (user) return <Navigate to='/dashboard' replace />;
    else
        return (
            <div className='flex items-center justify-center h-screen'>
                <div id='loginContainer' className='-mt-20 px-24 py-8 text-center justify-center shadow-lg'>
                    <img src={cmsMonitoringLogo} className='mx-auto mb-4' alt='CMS Monitoring' />
                    <h1>{message('Welcome')}!</h1>
                    <p className='mb-6'>{message('login-please')}</p>
                    <LoginForm />
                </div>
            </div>
        );
}
