import { useRouteError, useNavigate } from 'react-router-dom';
import { Button } from 'devextreme-react/ui/button';

export default function ErrorView() {
    const navigate = useNavigate();
    const error = useRouteError();

    return (
        <div className='flex items-center justify-center h-screen'>
            <div className='-mt-20 px-24 py-8 text-center justify-center shadow-lg max-w-2xl'>
                <h1>Error</h1>
                <p className='my-8'>{error.statusText || error.message}</p>
                <Button text='Back' onClick={() => navigate(-1)} />
            </div>
        </div>
    );
}
